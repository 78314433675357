import request from "@/utils/request";
import { CheckboxGroup } from "element-ui";
export function login(data) {
  return request({
    url: "/user/login",
    method: "post",
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: "/vue-admin-template/user/info",
    method: "get",
    params: {
      token: token
    }
  });
}
export function logout() {
  return request({
    url: "/user/ajaxLogout",
    method: "post"
  });
} // 修改密码

export function updatePassword(data) {
  return request({
    url: "/api/revise/user/updatePassword",
    method: "post",
    data: data
  });
} // 获取用户明细

export function queryUser(data) {
  return request({
    url: "/api/user/queryPage",
    method: "post",
    data: data
  });
} // 意见反馈 getFeedback

export function getFeedback(data) {
  return request({
    url: "/api/feed/back/queryPage",
    method: "post",
    data: data
  });
} // 文件明细  /user/file/queryPage

export function getFile(data) {
  return request({
    url: "/api/user/file/queryPage",
    method: "post",
    data: data
  });
} ///api/user/getCatIndexData

export function getCatIndexData() {
  return request({
    url: "api/user/getCatIndexData  ",
    method: "get"
  });
} //企业版查询申请

export function applyQueryPage(data) {
  return request({
    url: "/api/apply/queryPage",
    method: "post",
    data: data
  });
} //

export function passTheApplication(params) {
  return request({
    url: "api/apply/updated_user_apply_record",
    method: "get",
    params: params
  });
} //查询译员个人信息认证

export function getUserRegisterInfo(params) {
  return request({
    url: "api/user/get_user_register_info",
    method: "get",
    params: params
  });
} //查询企业版信息认证

export function getCompanyRegisterInfo(params) {
  return request({
    url: "api/user/get_company_register_info",
    method: "get",
    params: params
  });
} //处理企业认证记录

export function updatedCompanyRegister(params) {
  return request({
    url: "api/user/updated_company_register_record",
    method: "get",
    params: params
  });
} //处理个人认证记录

export function updatedUserRegister(params) {
  return request({
    url: "api/user/updated_user_register_record",
    method: "get",
    params: params
  });
} //分页查询学校数据（主页）

export function schoolQueryPage(data) {
  return request({
    url: "api/university/queryPage",
    method: "post",
    data: data
  });
}
export function schoolCount(data) {
  return request({
    url: "api/university/count",
    method: "post",
    data: data
  });
} // 获取所有省份

export function queryProvince(params) {
  return request({
    url: "api/district/queryProvince",
    method: "get",
    params: params
  });
} // 根据父节点编码查询地区

export function queryDistrict(data) {
  return request({
    url: "api/district/queryProvince",
    method: "post",
    data: data
  });
} // 分页查询学校用户数据明细

export function queryDetailUserPage(data) {
  return request({
    url: "api/university/queryDetailUserPage",
    method: "post",
    data: data
  });
} // 获取学校数据明细

export function getUniversity(data) {
  return request({
    url: "api/university/get",
    method: "post",
    data: data
  });
} // 更新学校数据明细

export function updateUpdate(data) {
  return request({
    url: "api/university/update",
    method: "post",
    data: data
  });
} // 分页查询导入用户文件列表

export function importPage(data) {
  return request({
    url: "api/import/queryPage",
    method: "post",
    data: data
  });
} // 获取文件上传签名等信息

export function getUploadSign(data) {
  return request({
    url: "api/import/getUploadSign",
    method: "post",
    data: data
  });
} // 新增用户导入文件

export function addUserImportFiles(data) {
  return request({
    url: "api/import/addUserImportFiles",
    method: "post",
    data: data
  });
} // 文件下载列表

export function queryFileDataDetails(data) {
  return request({
    url: "api/import/queryFileDataDetails",
    method: "post",
    data: data
  });
} // 文件状态查询

export function queryFileStates(data) {
  return request({
    url: "api/import/queryFileStates",
    method: "post",
    data: data
  });
} // 获取登录用户

export function getUserInfo() {
  return request({
    url: "/user/getSessionUser",
    method: "post"
  });
} // 注册

export function userRegister(data) {
  return request({
    url: "/user/userRegister",
    method: "post",
    data: data
  });
}