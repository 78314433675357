/* 编辑器内后端做过转义 */
var escapeHtml = function escapeHtml(str) {
  if (!str) return ''; // 转义&,一定要在最前处理，否则会把其它转义符号里的&也处理
  // str = str.replace(/&/g, '&amp;'); 
  // 转义html < > 符号,常用于页面显示录入的文本中包含html

  str = str.replace(/<html/g, '&lt;html');
  str = str.replace(/script>/g, 'script&gt;'); // str = str.replace(/"http:\/\//g, '&quot;http://');
  // str = str.replace(/'http:\/\//g, '&#x27;http://');

  str = str.replace(/<base(\s*)href(\s*)=/g, '&lt;base href=');
  str = str.replace(/(<\/?a.*?>)/g, '');
  str = str.replace(/onerror\s*=\s*['"]?[^'"]*['"]?/g, '');
  return str;
};

export default escapeHtml;