//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import { logout, getUserInfo } from '@/api/user'

export default {
  data() {
    return {
      aliasName: '', //用户名
      avatar: '',   //头像
    }
  },
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    routes() {
      return this.$router.options.routes
    },
    activeMenu() {
      const route = this.$route
      let { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      let pathArr = path.split('/')
      pathArr.pop()
      pathArr.push('index')
      path = pathArr.join('/')
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    },
  },
  async mounted() {
    //从飞译登录
    let loginInfo = localStorage.getItem("loginInfo")
    if (casEnabled && !loginInfo) {
      const { resultCode, result } = await getUserInfo()
      if (resultCode === "1") {
        this.user = result;
        let loginInfo = JSON.stringify(result)
        localStorage.setItem("loginInfo", loginInfo)
        //如果该用户有selfCompang，没有就用所在团队的code
        if (result.selfCompany.code !== "" || result.selfCompany.code) {
          localStorage.setItem("companyCode", result.selfCompany.code)
        } else {
          localStorage.setItem("companyCode", result.companyList[0].code)
        }
      }
      return
    } else if (!casEnabled && !loginInfo) {
      this.$router.push({ path: "/login" })
      return
    } else {
      let loginInfo = localStorage.getItem("loginInfo")
      this.user = JSON.parse(loginInfo)
    }
    console.log("user", this.user)
    this.aliasName = this.user.aliasName || this.user.loginName;
    //获取头像，没有就用默认头像
    this.avatar = this.user.avatar
    if (!this.avatar || this.avatar === '') {
      this.avatar = require("../../../assets/home/nantouxiang.png")
    }
  },
  methods: {
    //前往个人中心
    goToPersonal() {
      this.$router.push("/personal")
    },
    //退出登录
    async logout() {
      if (casEnabled) {
        localStorage.removeItem('loginInfo')
        let callback = encodeURIComponent(`${window.location.origin}/logout?client_name=${casClientName}`)
        window.location.href = `${casUrl}/logout?service=${callback}`;
      } else {
        const { resultCode } = await logout()
        if (resultCode === "1") {
          localStorage.removeItem("loginInfo")
          this.$router.push("/login")
        }
      }
    },
  }
}
